import {
  StandardText,
  StandardLabel,
  StandardTooltip,
  Colors,
  Placements,
  TooltipTypes,
  Widths,
  FontIcon,
  FontIcons,
  FontIconColors,
} from '@brandfolder/react';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { AddAssetLinkToggle } from '../asset-link-toggle/asset-link-toggle';
import { generateWorkfrontForms, generateWorkfrontProjectForms } from '../common/utils';
import { FormFieldsTagger } from '../form-fields-tagger/form-fields-tagger';
import { ProjectFormsTagger } from '../project-forms-tagger/project-forms-tagger';
import { WorkfrontFormsTagger } from '../workfront-forms-tagger/workfront-forms-tagger';
import './workfront-optional-metadata.scss';

export const WorkfrontOptionalMetadata = () => {
  const { control, setValue } = useFormContext();

  const workfrontDocumentCustomFields = useWatch({ control, name: 'document_custom_fields' });
  const workfrontUserCustomFields = useWatch({ control, name: 'user_custom_fields' });
  const workfrontProjectCustomFields = useWatch({ control, name: 'project_custom_fields' });
  const workfrontPortfolioCustomFields = useWatch({ control, name: 'portfolio_custom_fields' });
  const workfrontProgramCustomFields = useWatch({ control, name: 'program_custom_fields' });
  const workfrontTags = useWatch({ control, name: 'tags' });

  const [state, setState] = useState({
    formsLoaded: true,
    projectFormsLoaded: false,
    formFieldsLoaded: false,
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      formsLoaded:
        typeof workfrontDocumentCustomFields !== 'undefined' &&
        typeof workfrontUserCustomFields !== 'undefined' &&
        typeof workfrontProjectCustomFields !== 'undefined',
      projectFormsLoaded:
        typeof workfrontPortfolioCustomFields !== 'undefined' &&
        typeof workfrontProgramCustomFields !== 'undefined',
      formFieldsLoaded: typeof workfrontTags !== 'undefined',
    }));
  }, [
    workfrontDocumentCustomFields,
    workfrontUserCustomFields,
    workfrontProjectCustomFields,
    workfrontPortfolioCustomFields,
    workfrontProgramCustomFields,
    workfrontTags,
  ]);

  const resetTaggers = () => {
    const workfrontFormPresent =
      workfrontDocumentCustomFields || workfrontUserCustomFields || workfrontProjectCustomFields;

    if (state.formsLoaded && !workfrontFormPresent) {
      setValue('portfolio_custom_fields', false, { shouldDirty: true });
      setValue('program_custom_fields', false, { shouldDirty: true });
    }
    if (workfrontTags?.length === 0) {
      setValue('tags', null, { shouldDirty: true });
    }
  };

  useEffect(() => {
    resetTaggers();
  }, [state.formsLoaded, state.projectFormsLoaded, state.formFieldsLoaded]);

  useEffect(() => {
    if (state.formsLoaded) {
      generateWorkfrontForms(
        workfrontDocumentCustomFields,
        workfrontUserCustomFields,
        workfrontProjectCustomFields,
      );
    }
    if (state.projectFormsLoaded) {
      generateWorkfrontProjectForms(workfrontPortfolioCustomFields, workfrontProgramCustomFields);
    }
  }, [state.formsLoaded, state.projectFormsLoaded]);

  return (
    <section className="workfront-optional-metadata-container">
      <div className="workfront-optional-metadata-content">
        <StandardText className="optional-metadata-label">OPTIONAL METADATA</StandardText>
        <label className="workfront-forms-label-container">
          <StandardLabel htmlFor={'workfront-forms'}>Workfront forms</StandardLabel>
          <StandardTooltip
            color={Colors.Primary}
            defaultOpen={false}
            id="workfront-form-fields-tooltip"
            placement={Placements.TopCenter}
            tooltip="You can enter the Workfront form fields you want to sync as asset tags in Brandfolder. If a tag doesn’t exist, we’ll create it when the customer syncs the document."
            triggerOffset={8}
            type={TooltipTypes.Description}
            width={Widths.Medium}
          >
            <FontIcon icon={FontIcons.ToolTip} color={FontIconColors.Primary} />
          </StandardTooltip>
        </label>
        {state.formsLoaded && <WorkfrontFormsTagger />}
        {state.projectFormsLoaded && <ProjectFormsTagger />}
        {state.formFieldsLoaded && <FormFieldsTagger />}
        <AddAssetLinkToggle />
      </div>
    </section>
  );
};
