import { StandardTagger } from '@brandfolder/react';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  formOptionList,
  generateWorkfrontForms,
  handleFormOptionUpdateByForm,
} from '../common/utils';

export const WorkfrontFormsTagger = () => {
  const { control } = useFormContext();

  const workfrontDocumentCustomFields = useWatch({ control, name: 'document_custom_fields' });
  const workfrontUserCustomFields = useWatch({ control, name: 'user_custom_fields' });
  const workfrontProjectCustomFields = useWatch({ control, name: 'project_custom_fields' });

  const onTagged = handleFormOptionUpdateByForm();

  return (
    <StandardTagger
      id="workfront-forms-tagger"
      data-testid="workfront-forms-tagger"
      labels={{
        iconButtonLabel: 'Toggle list of Workfront forms',
        label: 'Workfront forms',
        listboxLabel: 'List of Workfront forms',
        removeTagLabel: 'Remove Workfront form',
      }}
      showLabel={false}
      onTagged={onTagged}
      allowCustomValue={false}
      allowDuplicates={false}
      options={formOptionList('workfront_forms')}
      initialTags={generateWorkfrontForms(
        workfrontDocumentCustomFields,
        workfrontUserCustomFields,
        workfrontProjectCustomFields,
      )?.map((item) => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return {
          value: value.toString(),
          children: <>{value}</>,
          disabled: false,
          key: key,
          search: value.toString(),
        };
      })}
    />
  );
};
